import { RouterView } from "vue-router";

export const testUsersRoute = {
  path: ":menu/test-users",
  component: <RouterView />,
  children: [
    {
      path: "",
      component: () => import("@/views/test-users/list/index.vue"),
    },
  ],
};
