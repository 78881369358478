import { RouterView } from "vue-router";

export const deviceUpgradesRoute = {
  path: ":menu/device-upgrades",
  component: <RouterView />,
  children: [
    {
      path: "",
      component: () => import("@/views/device-upgrades/list/index.vue"),
    },
  ],
};
