export const WorkData = {
  realTime: {
    commands: {
      function: "40",
      request: "55 07 40 00 00 00 00",
      requestPrefix: "55",
      responsePrefix: "AA",
    },
    primary: {
      voltage: {
        keyName: "电压",
        value: 0,
        unit: "V",
        percent: 0,
        percentBase: {
          data: "setupParams",
          key: "voltageMax",
        },
        indexes: [3, 4],
        times: 100,
      },
      electricCurrent: {
        keyName: "电流",
        value: 0,
        valueDecimalPlaces: 0,
        unit: "A",
        percent: 0,
        percentBase: {
          data: "setupParams",
          key: "electricCurrentMax",
        },
        indexes: [5, 6],
        times: 10,
      },
      power: {
        keyName: "功率",
        value: 0,
        unit: "W",
        percent: 0,
        indexes: [9, 10],
        times: 1,
      },
      rotationRate: {
        keyName: "转速",
        value: 0,
        unit: "RPM",
        percent: 0,
        percentBase: {
          data: "setupParams",
          key: "rotationRateMax",
        },
        indexes: [7, 8],
        times: 1,
      },
    },
    secondary: {
      oil: {
        keyName: "剩余燃料",
        value: 0,
        valueRange: [0, 100],
        percentUnit: "%",
        unit: "L",
        percent: 0,
        percentIndex: 33,
        indexes: [31, 32],
        times: 10,
      },
      unit: {
        keyName: "机组温度",
        value: 0,
        valueRange: [-50, 150],
        unit: "℃",
        percent: 0,
        percentBase: 200,
        indexes: [21, 22],
        times: 1,
      },
      controller: {
        keyName: "控制器温度",
        value: 0,
        valueRange: [-50, 150],
        unit: "℃",
        percent: 0,
        percentBase: 200,
        indexes: [19, 20],
        times: 1,
      },
    },
    abnormalParams: {
      indexes: [52, 49, 50, 51],
    },
  },
  setupParams: {
    commands: {
      function: "41",
      request: "55 07 41 00 00 00 00",
      requestPrefix: "55",
      responsePrefix: "AA",
    },
    params: {
      voltageMax: {
        keyName: "最大电压",
        value: 0,
        indexes: [43, 44],
        times: 100,
      },
      electricCurrentMax: {
        keyName: "最大电流",
        value: 0,
        indexes: [47, 48],
        times: 10,
      },
      voltage: {
        keyName: "输出电压",
        value: 0,
        indexes: [13, 14],
        times: 100,
      },
      rotationRateMax: {
        keyName: "最大转速",
        value: 0,
        indexes: [53, 54],
        times: 1,
      },
    },
  },
  operations: {
    commands: {
      function: "bb",
      request: "55 07 bb aa 55 00 {button}",
      response: "AA 03 BB {cs}",
    },
    buttons: {
      auto: "00",
      stop: "01",
      start: "02",
    },
  },
};
