export const StopShutdown = [
  {
    label: "关闭",
    value: 0,
  },
  {
    label: "30分钟",
    value: 30,
  },
  {
    label: "45分钟",
    value: 45,
  },
  {
    label: "60分钟",
    value: 60,
  },
  {
    label: "75分钟",
    value: 75,
  },
  {
    label: "90分钟",
    value: 90,
  },
  {
    label: "105分钟",
    value: 105,
  },
  {
    label: "120分钟",
    value: 120,
  },
];
