export const AbnormalParam = [
  ["自动", "启动", "", "", "", "", "", ""],
  [
    "U相线故障",
    "V相线故障",
    "W相线故障",
    "整流桥检测异常",
    "启动继电器故障",
    "油泵或油阀继电器故障",
    "机油报警",
    "机油保养",
  ],
  [
    "自动电压过高保护",
    "电压过高保护",
    "电压过低保护",
    "启动电机故障",
    "引擎启动失败",
    "电流运放初始值故障",
    "发电过流提示",
    "发电过流保护",
  ],
  [
    "油门步进电机高故障",
    "油门步进电机低故障",
    "控制器温度高",
    "机组温度高",
    "flash读写错误",
    "",
    "",
    "",
  ],
];
