import { keyMirror } from "jt-helpers";
import { publicAccountsApi } from "@/apis/public/accounts";

const state = {
  user: {
    token: "",
  },
};

const types = keyMirror({
  SetUser: null,
  ClearUser: null,
});

const mutations = {
  [types.SetUser](state, { user }) {
    state.user = user;
  },
  [types.ClearUser](state) {
    state.user = {
      token: "",
    };
  },
};

const actions = {
  async login({ commit }, { account, password }) {
    const { user, token } = await publicAccountsApi.post({
      action: "login",
      body: { account, password },
    });

    commit(types.SetUser, { user: { ...user, token } });

    return { user: { ...user, token } };
  },
  logout({ commit }) {
    commit(types.ClearUser);
    return null;
  },
};

export const auth = {
  namespaced: true,
  state,
  mutations,
  actions,
};
