export const SetupData = {
  realTime: {
    commands: {
      function: "40",
      request: "55 07 40 00 00 00 00",
      requestPrefix: "55",
      responsePrefix: "AA",
    },
    usedTimes: {
      allPower: {
        keyName: "总使用",
        unit: "KW.h",
        value: 0,
        indexes: [45, 48],
        times: 10000,
      },
      currentUsedTime: {
        keyName: "当前使用时间",
        unit: "H",
        value: 0,
        indexes: [37, 40],
        times: 60,
      },
      allTime: {
        keyName: "总计时间",
        unit: "H",
        value: 0,
        indexes: [41, 44],
        times: 60,
      },
    },
  },
  setupParams: {
    getCommands: {
      function: "41",
      request: "55 07 41 00 00 00 00",
      requestPrefix: "55",
      responsePrefix: "AA",
    },
    setCommands: {
      function: "14",
      request: "55 13 14",
      requestPrefix: "55",
      responsePrefix: "AA",
    },
    params: {
      selfStartVoltage: {
        keyName: "自启动电压",
        enum: "SelfStartVoltage",
        field: "selfStartVoltage",
        unit: "V",
        value: 0,
        indexes: [7, 8],
        setIndexes: [15, 16],
        times: 100,
        condition: "<",
      },
      selfFlameoutCurrent: {
        keyName: "自熄火电流",
        enum: "SelfFlameoutCurrent",
        field: "selfFlameoutCurrent",
        unit: "A",
        value: 0,
        indexes: [25, 26],
        setIndexes: [9, 10],
        times: 10,
        condition: "<",
      },
      selfFlameoutVoltage: {
        keyName: "自熄火电压",
        enum: "SelfFlameoutVoltage",
        field: "selfFlameoutVoltage",
        unit: "V",
        value: 0,
        indexes: [19, 20],
        setIndexes: [7, 8],
        times: 100,
        condition: ">",
      },
      outputVoltage: {
        keyName: "输出电压",
        enum: "OutputVoltage",
        field: "outputVoltage",
        unit: "V",
        value: 0,
        indexes: [13, 14],
        setIndexes: [5, 6],
        times: 100,
        condition: "=",
      },
      selfStartDelay: {
        keyName: "自启动延时",
        field: "selfStartDelay",
        value: 0,
        indexes: [31, 32],
        setIndexes: [11, 12],
        times: 1,
        hide: true,
      },
      selfFlameoutDelay: {
        keyName: "自熄火延时",
        field: "selfFlameoutDelay",
        value: 0,
        indexes: [33, 34],
        setIndexes: [13, 14],
        times: 1,
        hide: true,
      },
      engineTemperature: {
        keyName: "发电机温度过高",
        field: "engineTemperature",
        value: 0,
        indexes: [41, 42],
        times: 1,
        hide: true,
      },
      cpuTemperature: {
        keyName: "CPU温度过高保护",
        field: "cpuTemperature",
        value: 0,
        indexes: [39, 40],
        times: 1,
        hide: true,
      },
    },
  },
  stopShutdownParams: {
    commands: {
      function: "16",
      request: "55 07 16 00 00 {time}",
      response: "AA 03 16 {cs}",
      requestPrefix: "55",
      responsePrefix: "AA",
    },
    params: {
      stopShutdown: {
        keyName: "预约停机",
        enum: "StopShutdown",
        field: "stopShutdown",
        unit: "V",
        value: 0,
        indexes: [3, 4],
        times: 60,
        condition: "",
      },
    },
  },
  ranges: {
    commands: {
      function: "41",
      request: "55 07 41 00 00 00 00",
      requestPrefix: "55",
      responsePrefix: "AA",
    },
    params: {
      selfStartVoltageMax: {
        keyName: "自启动电压（高限制）",
        value: 0,
        indexes: [3, 4],
        times: 100,
      },
      selfStartVoltageMin: {
        keyName: "自启动电压（低限制）",
        value: 0,
        indexes: [5, 6],
        times: 100,
      },
      selfFlameoutCurrentMax: {
        keyName: "自熄火电流（高限制）",
        value: 0,
        indexes: [21, 22],
        times: 10,
      },
      selfFlameoutCurrentMin: {
        keyName: "自熄火电流（低限制）",
        value: 0,
        indexes: [23, 24],
        times: 10,
      },
      selfFlameoutVoltageMax: {
        keyName: "自熄火电压（高限制）",
        value: 0,
        indexes: [15, 16],
        times: 100,
      },
      selfFlameoutVoltageMin: {
        keyName: "自熄火电压（低限制）",
        value: 0,
        indexes: [17, 18],
        times: 100,
      },
      outputVoltageMax: {
        keyName: "输出电压（高限值）",
        value: 0,
        indexes: [9, 10],
        times: 100,
      },
      outputVoltageMin: {
        keyName: "输出电压（低限制）",
        value: 0,
        indexes: [11, 12],
        times: 100,
      },
    },
  },
  operations: {
    commands: {
      function: "a7",
      request: "55 07 a7 aa 55 aa 55",
      response: "AA 03 A7 {cs}",
    },
  },
};
