import { RouterView } from "vue-router";

export const sellersRoute = {
  path: ":menu/sellers",
  component: <RouterView />,
  children: [
    {
      path: ":type-sellers",
      component: () => import("@/views/sellers/list/index.vue"),
    },
    {
      path: "clients",
      component: () => import("@/views/clients/list/index.vue"),
    },
    {
      path: "clients/:userId/devices",
      component: () => import("@/views/devices/list/index.vue"),
    },
  ],
};
