import { AbnormalParam } from "@/utils/consts/abnormal-param";
import { DeviceCommands } from "@/utils/consts/device-commands";
import { SetupData } from "@/utils/consts/setup-data";
import { StopShutdown } from "@/utils/consts/stop-shutdown";
import { WorkData } from "@/utils/consts/work-data";

export const useConsts = () => {
  // 基础地址
  const BaseUrl = process.env["BASE_URL"];

  // 接口地址
  const ApiUrl = process.env["VUE_APP_API_URL"];

  // 静态文件地址
  const StaticUrl = process.env["VUE_APP_STATIC_URL"];

  // WebSocket 地址
  const WsUrl = process.env["VUE_APP_WS_URL"];

  // 分页大小
  const PageSize = 10;

  return {
    AbnormalParam,
    DeviceCommands,
    SetupData,
    StopShutdown,
    WorkData,
    BaseUrl,
    ApiUrl,
    StaticUrl,
    WsUrl,
    PageSize,
  };
};
