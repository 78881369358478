import { accountsRoute } from "./accounts";
import { apiLogsRoute } from "./api-logs";
import { clientsRoute } from "./clients";
import { deviceErrorsRoute } from "./device-errors";
import { deviceModelsRoute } from "./device-models";
import { deviceUpgradesRoute } from "./device-upgrades";
import { homeRoute } from "./home";
import { manufacturersRoute } from "./manufacturers";
import { sellersRoute } from "./sellers";
import { testUsersRoute } from "./test-users";
import { usersRoute } from "./users";

export default [
  accountsRoute,
  apiLogsRoute,
  clientsRoute,
  deviceErrorsRoute,
  deviceModelsRoute,
  deviceUpgradesRoute,
  homeRoute,
  manufacturersRoute,
  sellersRoute,
  testUsersRoute,
  usersRoute,
];
