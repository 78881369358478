export const DeviceCommands = {
  // 召测控制器信息
  deviceInfo: {
    command: {
      function: "43",
      request: {
        data: "55 07 43 00 00 00 00",
        head: "55",
      },
      response: {
        head: "AA",
      },
    },
    params: {
      model: {
        indexes: [3, 24],
      },
      version: {
        indexes: [35, 41],
      },
      serialNo: {
        indexes: [44, 50],
      },
    },
  },
};
